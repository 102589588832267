import { v4 as uuidv4 } from 'uuid';
import { IPolygon } from './polygon.interface';

export interface ITreeNode {
  id: string;
  data: any;
  children: ITreeNode[];
  level?: number;
  isExpanded?: boolean;
  type: TreeNodeType;
  size?: string;
  note?: string;
  notesArray?: string[];
  polygons?: any;
  groupImages?: any;
  keyImageId?: string;
  valueImageId?: string;
  isModelType?: boolean;
  modelType?: string;
  groupId?:string;
  narration?:string;
  labels?:string;
  isFeatures?:boolean;
  isCertificates?:boolean;
  isDocuments?:boolean;
  isMap?:boolean;
  isProjectScope?:boolean;

}

export enum TreeNodeType {
  Heading = 'heading',
  Image = 'image',
  Text = 'text',
  Relation = 'relation',
  Timeline = 'timeline',
  Models = 'models',
  Features = 'features',
  FINDINGS_TBL ='findings_table',
  SEVERITY_TBL ='severity_table',
  Maps = 'maps',
  HTML = 'html',

}

export class TreeNode implements ITreeNode {
  id: string;
  data: string;
  children: ITreeNode[] = [];
  type: TreeNodeType;
  level?: number;
  size?: string;
  polygons?: IPolygon[];
  textBorder?: boolean;
  constructor(
    type: TreeNodeType,
    data: any,
    level?: number,
    polygons?: IPolygon[],
    textBorder?: boolean,
  ) {
    this.id = uuidv4(),
      this.data = data;
    this.type = type;
    if (polygons) {
      this.polygons = polygons;
    }
    if (level) {
      this.level = level;
    }
    if (textBorder) {
      this.textBorder = textBorder;
    }
    // if (type === TreeNodeType.Image) {
    //   this.size = 'medium';
    // }
  }
}
