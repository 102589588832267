<div class="popup-header w-100 d-flex">
    <p class="w-100">
      Report Blocks
    </p>


    <button matTooltip="Save" (click)="saveContent()" [disabled]="!name || !selectedOption" mat-raised-button  color="primary" class="dark">
        <mat-icon>save</mat-icon>
    </button>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-container bg full h-100 position-relative px-3 pt-0">
   
    <div class="card m-1 p-2">
        <div class="form-field mt-2 align-items-center">
            <input id="report-name-input" type="test" maxLength="64" required [(ngModel)]="name"
                 class="form-control input-field report-name-input" placeholder="Add Block Name"
                name="reportName" />
        </div>

          <div class="form-field mt-5 align-items-center">
            <label>
              <span>Usability with</span> 
              <mat-icon 
    style="position: relative; top: 5px;" 
    [matTooltip]="'This block is usable across the content specified. \n' +
                  'Company wide: applies to all assets inspected by the company. \n' +
                  'Asset wide: applies to all inspections done under that specific asset. \n' +
                  'Inspection only: refers to usability for only reporting of this inspection and can be used by all users.'"
    matTooltipClass="custom-tooltip">
    help
</mat-icon>
            </label>
            
            <mat-form-field class="w-100"> 
              
        <mat-select  [(ngModel)]="selectedOption">
          <mat-option [disabled]="isAssetInfo" value="domain">Company wide</mat-option>
          <mat-option value="asset">Asset wide</mat-option>
          <mat-option value="project">This inspection only</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="icon-container d-flex justify-content-center align-items-center mb-3">
        <div class="icon-item" matTooltip="Add asset info block" (click)="assetInfo()">
          <img src="assets/assets-info.svg" width="22" alt="Asset Info">
        </div>    
        <div class="icon-item" matTooltip="Add HTML block" (click)="new()" >
          <!-- <mat-icon>code</mat-icon> -->
          <img src="assets/html-block.jpg" width="22" alt="Asset Info">
        </div>
      </div>
    </div>

    <div class="editor-container editor-styles">
        <div *ngFor="let node of nodes">
            <mat-card>
                <p class="d-flex align-items-center">
                    <span class="block-title">Block title:</span>
                    <input type="text" [(ngModel)]="node.title" style="flex-grow: 1;" class="form-control" />
                    <mat-icon [ngStyle]="{ color: '#EB5757' }" class="ms-2" (click)="removeNode(node.id)">close</mat-icon>
                </p>
                 <div id="editor{{ node.id }}"   contenteditable="true"></div>
                 <!-- <textarea 
                 id="editor{{ node.id }}" 
                 appSummernote 
                
                 (ngModelChange)="updateEditorContent(node.id, $event)">
               </textarea> -->
            </mat-card>
           
        </div>
        <div>

        </div>
    </div>