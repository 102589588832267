<div class="popup-header w-100 d-flex">
    <p class="w-100">
      Report Blocks
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>
  <mat-dialog-content >
    <div style="display: flex; justify-content: flex-end; margin-bottom: 20px;">
      <button  class="mr-2"  mat-raised-button
        color="primary"
        type="button"
        [disabled]="!selectedBlocks.length"
        (click)="addGroups()"
      >
        <span>Add</span>
      </button>
    </div>
  
    <!-- <input type="text" class="form-control table-form-control" placeholder="Filter by object or folder name prefix" (input)="filterTitles($event)"> -->
    <div class="auto scroller w-100" style="height:calc(100vh - 160px);overflow-y:scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="" style="width: 40px;">&nbsp;</th>
            <th scope="col" class="text-uppercase table-header">Block Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of reportBlocks">
            <td>
              <mat-checkbox
                [checked]="isGroupSelected(group)"
                [color]="'primary'"
                (click)="toggleGroup($event, group)"
              >
              </mat-checkbox>
            </td>
            <td>
              <div>{{ group.name }}</div>
            </td>
          </tr>
          <tr class="no-item">
            <td colspan="3">
             No Groups
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  
  </mat-dialog-content>
  