import { Component, OnInit,OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend.service';
@Component({
  selector: 'app-report-block-group',
  templateUrl: './report-block-group.component.html',
  styleUrls: ['./report-block-group.component.scss']
})
export class ReportBlockGroupComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
public selectedBlocks =[];
public reportBlocks =[];
  constructor(
    public dialogRef: MatDialogRef<ReportBlockGroupComponent>,
    public dialog: MatDialog,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.getReportBlocks(this.data.domain,this.data.assetId,this.data.projectId)
  }

  getReportBlocks(domain,assetId,projectId){
    this.backendService.getReportsBlocks(domain).pipe(takeUntil(this.onDestroy$)).subscribe((blocks) => {
      this.reportBlocks= blocks.filter(o => o.assetId === assetId || o.projectId ===  projectId);
    })
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleGroup(event: MouseEvent, group: any): void {
    event.stopPropagation();
    event.preventDefault();
    const currentVal = this.selectedBlocks;
    let newVal;
    if (this.isGroupSelected(group)) {
      newVal = currentVal.filter(item => item.id !== group.id);
    } else {
      newVal = [...currentVal, group];
    }
    this.selectedBlocks = newVal;
  }

  isGroupSelected(group: any): boolean {
    return this.selectedBlocks.some(item => item.id === group.id);
  }
  
  addGroups(): void {
    const selected = this.selectedBlocks;
    this.dialogRef.close(selected);
  }
}