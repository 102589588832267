import { Component, forwardRef, Input, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare var ClassicEditor: any;

@Component({
  selector: 'app-ckeditor',
  template: '<div #editor></div>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CKEditorComponent),
      multi: true,
    },
  ],
})
export class CKEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('editor', { static: true }) editor: ElementRef;
  @Input() config: any;
  private editorInstance: any;

  private onChange = (value: any) => {};
  private onTouched = () => {};

  ngAfterViewInit() {
    ClassicEditor
      .create(this.editor.nativeElement, this.config)
      .then((editor: any) => {
        this.editorInstance = editor;

        // Handle data changes in the editor
        editor.model.document.on('change:data', () => {
          const value = editor.getData();
          this.onChange(value);
          this.onTouched();
        });

        // Set initial data if available
        if (this.value) {
          this.editorInstance.setData(this.value);
        }

        // Disable drag-and-drop of CKEditor elements outside of the editor
       // this.disableExternalDrag(editor);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  ngOnDestroy() {
    if (this.editorInstance) {
      this.editorInstance.destroy()
        .catch((error: any) => {
          console.error(error);
        });
    }
  }

  private value: any;

  writeValue(value: any): void {
    this.value = value;
    if (this.editorInstance) {
      this.editorInstance.setData(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Disable dragging CKEditor elements outside the editor
  disableExternalDrag(editor: any): void {
    const viewDocument = editor.editing.view.document;

    // Prevent dragging elements outside the editor
    viewDocument.on('dragstart', (evt: any, data: any) => {
      data.preventDefault();
    });

    // Optional: Prevent dragging selected text outside the editor
    viewDocument.on('drag', (evt: any, data: any) => {
      data.preventDefault();
    });
  }
}
