import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UiService } from './services/ui.service';
import { ConnectionService } from 'ng-connection-service';
import { BackendService } from './services/backend.service';
import { Subject, Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dive';
  isUserLoggedIn: boolean = false;
  user: any;
  interval: any;
  noInternetConnection: boolean = false;
  userSubsription: Subscription;

  constructor(
    private backend: BackendService, private uiService: UiService, public authProcess: AngularFireAuth, private router: Router, private connectionService: ConnectionService) {
    const auth = authProcess;
    this.authProcess.authState.subscribe(user => {
      if (!user) {
        // User is logged out
        // Redirect or perform any other action
      } else {
        // User is logged in
        // Check if token needs refreshing after logout
        // Force token refresh to invalidate the token
        user.getIdToken(true)
          .then(() => console.log('Token refreshed successfully after logout'))
          .catch(error => console.error('Error refreshing token after logout', error));
      }
    });    

    this.connectionService.monitor().subscribe(isConnected => {
      if (isConnected) {
        this.noInternetConnection = false;
      }
      else {
        this.noInternetConnection = true;
      }
    })
  }

  logout() {
    this.backend.tokenUpdate(this.user.uid, null).then(user => {
      this.authProcess.signOut().then(res => {
        document.location.reload();
      })
    })

  }
}